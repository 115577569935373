import React from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';
import HomePageContainer
  from '@fuww/library/src/Articles/Homepage/Container';
import Head from '../Head';
import ArticleLayout from '../News/ArticleLayout';
import { useSiteContext } from '../SiteContext';
import InView from '../InView';
import messages from '../../lib/messages.mjs';
import getSearchJsonLdData from '../../lib/getSearchJsonLdData';
import loading from '../../lib/loading';
import {
  getFeaturedVariables, getMoreNewsVariables,
} from '../../lib/getNewsVariables';

const DynamicCategoryNewsSection = dynamic(() => import(
  '../News/Homepage/CategoryNews'
), {
  ssr: false,
  loading,
});

const Index = () => {
  const intl = useIntl();
  const router = useRouter();
  const { locale, newsboard } = useSiteContext();

  return (
    <HomePageContainer>
      <Head
        title={intl.formatMessage(messages.indexTitle)}
        description={intl.formatMessage(messages.indexDescription)}
        jsonLdData={getSearchJsonLdData(router, intl)}
      />
      <ArticleLayout
        categorySection={(
          <InView>
            <DynamicCategoryNewsSection />
          </InView>
        )}
        showCreatedAt
        expandedSections
        featuredVariables={getFeaturedVariables(newsboard)}
        moreNewsVariables={getMoreNewsVariables(locale)}
      />
    </HomePageContainer>
  );
};

export default Index;
