const getFeaturedVariables = (newsboard?: string) => newsboard && ([
  'FASHIONUNITED_INFO',
].includes(newsboard)
  ? {
    featured: null,
  }
  : null
);

const getMoreNewsVariables = (locale?: string) => locale && ([
  'fr-FR',
].includes(locale)
  ? {
    featured: null,
  }
  : null
);

export { getFeaturedVariables, getMoreNewsVariables };
