import Routes from '../routes.mjs';
import messages from './messages.mjs';

const getSearchJsonLdData = (router, intl) => {
  const { prefix } = router.query;
  const searchUrl = Routes.findAndGetUrls(
    'search',
    {
      prefix,
      searchPath: intl.formatMessage(messages.searchPath),
    },
  ).urls.as;
  const homeUrl = Routes.findAndGetUrls(
    'index',
    { prefix },
  ).urls.as;

  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: homeUrl,
    potentialAction: {
      '@type': 'SearchAction',
      target: `${searchUrl}?keywords={keywords}`,
      'query-input': 'required name=keywords',
    },
  });
};

export default getSearchJsonLdData;
