import styled from '@emotion/styled';
import breakpoints from '../../Theme/breakpoints';

const HomePageContainer = styled.div`
  padding: 0 16px;

  @media (min-width: ${breakpoints.sm}px) {
    padding: 16px;
  }

  @media (min-width: ${breakpoints.lg}px) {
    padding: 24px;
  }
`;

export default HomePageContainer;
